import React, { useRef, useState } from "react";
import { GiBrain } from 'react-icons/gi';
import { MdAlternateEmail } from 'react-icons/md';
import { BsFillPinMapFill } from 'react-icons/bs';

import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_bdfl0ie', 'template_jhckdrv', form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                setButtonText("Message Sent");

                // Reset the form and button text after 3 seconds
                setTimeout(() => {
                    e.target.reset();
                    setButtonText("Submit");
                }, 3000);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <section className="contact-container container">
            <div className="get_form_inner">
                <div className="get_form_inner_text">
                    <h3>Get In Touch</h3>
                    <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="inputs">
                        <input type="text" name='name' placeholder='Your Name' required pattern="[A-Za-z\s]+" title="Only letters and spaces allowed" /><br />
                        <input type="email" name='email' placeholder='Your Email' required /><br />
                        <input type="tel" name='phone' placeholder='Your Phone' required pattern="[0-9]{10,}" title="Enter a valid phone number" /><br />
                        <input type="text" name='subject' placeholder='Subject' required /><br />
                    </div>
                    <div>
                        <textarea name="message" placeholder="How can we help?" cols="50" rows="8" required></textarea>
                        <input type="submit" value={buttonText} />
                    </div>
                </form>
            </div>

            <div className="contact-cards">
                <div className="contact-card">
                    <div className="contact-card-icon">
                        <h3><GiBrain /></h3>
                        <p>SOBER LIVING</p>
                        <a href="tel:7147663251">(714) 766-3251</a>
                    </div>
                </div>

                <div className="contact-card">
                    <div className="contact-card-icon">
                        <h3><MdAlternateEmail /></h3>
                        <p>EMAIL</p>
                        <h5>info@NewWaveRecovery.net</h5>
                    </div>
                </div>

                <div className="contact-card">
                    <div className="contact-card-icon">
                        <h3><BsFillPinMapFill /></h3>
                        <p>LOCATION</p>
                        <h5>1234 Street Name, Huntington Beach CA 00000</h5>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactForm;
